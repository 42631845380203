<template>
  <section class="-mx-4 px-2 flex flex-col overflow-x-hidden overflow-y-auto scroll-smooth overscroll-none divide-y divide-white/10">
    <!-- filter -->
    <section v-for="(topic, topicid) in filterTopics" :key="`topic-${topicid}`" class="py-1 default-transition">
      <!-- title -->
      <button class="w-full p-2 flex items-center gap-x-2 rounded-xl hover:bg-zinc-800/50" @click="topic.collapse = !topic.collapse">
        <!-- icon -->
        <component
          v-if="cardFilters[topic.field]?.length === 0"
          :is="iconMap[`${cardFilters[topic.field]?.length > 0 ? 'Solid' : ''}${topic.icon}`]"
          class="flex-none size-6 stroke-2 text-zinc-200"
        />
        <span v-else class="size-6 text-mono font-bold text-white grid content-center text-center text-white bg-cyan-500 rounded-full">
          {{ topic.field === 'keyword' ? cardFilters[topic.field]?.split(' ').filter(e => e)?.length : cardFilters[topic.field]?.length }}
        </span>

        <!-- title -->
        <h3
          class="text-lg font-bold mr-auto"
          :class="{
            'text-zinc-200': cardFilters[topic.field]?.length === 0,
            'text-cyan-200': cardFilters[topic.field]?.length > 0
          }"
        >
          {{ $t(`paneFilter.${topic.field}`) }}
        </h3>

        <!-- <span
          class="ml-auto text-right flex-none font-mono text-zinc-500 dark:text-zinc-200"
          :class="{ 'font-bold': cardFilters[topic.field]?.length > 0 }"
        >
          {{ topic.field === 'keyword' ? cardFilters[topic.field].split(' ').filter((e) => e).length : cardFilters[topic.field]?.length }}/{{
            topic.field === 'sort' ? sorts.length : topic.field === 'favorite' ? favorites.length : filters[topic.field]?.length || '-'
          }}
        </span> -->

        <template v-if="['keyword', 'sort'].includes(topic.field)">
          <UIButton class="btn-sm bg-black text-white hover:bg-red-500" @click.stop="doTrash(topic.field)">
            <TrashIcon />
          </UIButton>
        </template>

        <template v-else-if="topic.field !== 'favorite'">
          <UIButton
            v-if="cardFilters[topic.field]?.length === 0"
            class="btn-sm bg-black text-white hover:bg-green-500"
            @click.stop="cardFilters[topic.field] = JSON.parse(JSON.stringify(filters[topic.field]))"
          >
            <CheckCircleIcon />
          </UIButton>

          <UIButton v-else class="btn-sm bg-black text-white hover:bg-red-500" @click.stop="doTrash(topic.field)">
            <TrashIcon />
          </UIButton>
        </template>

        <UIButton class="btn-sm bg-black text-white hover:bg-amber-500" @click.stop="topic.collapse = !topic.collapse">
          <ChevronDownIcon
            class="default-transition"
            :class="{
              'rotate-180': !topic.collapse
            }"
          />
        </UIButton>
      </button>

      <!-- content -->
      <div
        class="px-2 flex flex-wrap gap-2 default-transition"
        :class="{
          'max-h-0 opacity-0 overflow-hidden': topic.collapse,
          'py-4': !topic.collapse
        }"
      >
        <template v-if="topic.field === 'favorite'">
          <span class="text-zinc-400 text-sm"> {{ $t('paneFilter.favoriteInfo') }} </span>

          <div class="w-full flex items-center gap-2 flex-wrap rounded-xl">
            <UIButton class="btn-sm bg-green-200 text-green-700" @click="addFavorite">
              <PlusIcon />
            </UIButton>

            <OnLongPress
              as="button"
              class="rounded-xl py-1 px-2 min-w-[calc((100%-2rem)/5)] sm:min-w-[5rem] md:min-w-[calc((100%-2rem)/5)] default-transition hover:bg-zinc-700 bg-zinc-800 text-white select-none"
              v-for="(favorite, favoriteid) in setting.filterFavorite"
              :key="`favorite-${favoriteid}`"
              @click="cardFilters = JSON.parse(JSON.stringify(favorite.value))"
              @trigger="
                () => {
                  doVibrate('longPress', setting.vibrate)
                  doDelete(favorite, 'favorite')
                }
              "
            >
              <span class="whitespace-pre-line select-none">{{ favorite.name }}</span>
            </OnLongPress>
          </div>
        </template>

        <template v-else-if="topic.field === 'keyword'">
          <span class="text-zinc-400 text-sm">
            {{ $t('paneFilter.keywordInfo1') }}<br />
            {{ $t('paneFilter.keywordInfo3') }} "{{ cardFilters.keyword }}"
          </span>

          <div
            class="w-full flex items-center gap-2 rounded-xl p-2 default-transition"
            :class="{
              'hover:bg-zinc-700 bg-zinc-800 text-white': cardFilters.keyword === '',
              'hover:bg-sky-300 bg-sky-200 text-sky-900': cardFilters.keyword !== ''
            }"
          >
            <ArrowRightIcon class="flex-none size-4" />
            <UIInput type="text" v-model.trim="cardFilters.keyword" :placeholder="$t('paneFilter.keywordPlaceholder')" />

            <button
              class="rounded-full p-1 size-7 grid place-content-center"
              :class="{
                'hover:text-orange-800 hover:bg-orange-200 text-orange-700 bg-orange-200/50': cardFilters.keywordRule === 'AND',
                'hover:text-blue-800 hover:bg-blue-200 text-blue-700 bg-blue-200/50': cardFilters.keywordRule === 'OR'
              }"
              @click="cardFilters.keywordRule = cardFilters.keywordRule === 'AND' ? 'OR' : 'AND'"
            >
              <span class="text-xs font-mono"> {{ cardFilters.keywordRule }} </span>
            </button>

            <button
              class="rounded-full p-1 hover:text-green-800 hover:bg-green-200 text-green-700 bg-green-200/50"
              @click="
                setting.filterKeyword.includes(cardFilters.keyword) || cardFilters.keyword === ''
                  ? null
                  : setting.filterKeyword.push(cardFilters.keyword)
              "
            >
              <PlusIcon class="size-5" />
            </button>
          </div>

          <div class="w-full flex items-center gap-2 flex-wrap">
            <span class="w-full text-zinc-400 text-sm">
              {{ $t('paneFilter.keywordInfo2') }}
            </span>

            <template v-if="setting.filterKeyword.length > 0">
              <OnLongPress
                as="button"
                class="rounded-xl py-1 px-2 min-w-[calc((100%-2rem)/5)] sm:min-w-[5rem] md:min-w-[calc((100%-2rem)/5)] bg-zinc-800 hover:bg-zinc-700 select-none"
                v-for="(filterKeyword, filterKeywordid) in setting.filterKeyword"
                :key="`filterKeyword-${filterKeywordid}`"
                @click="
                  () => {
                    cardFilters.keyword = filterKeyword
                  }
                "
                @trigger="
                  () => {
                    doVibrate('longPress', setting.vibrate)
                    doDelete(filterKeyword, 'keyword')
                  }
                "
              >
                <span class="whitespace-pre-line select-none text-white">{{ filterKeyword }}</span>
              </OnLongPress>
            </template>

            <span class="text-zinc-400 text-sm" v-else>
              {{ $t('paneFilter.keywordDefaultEmpty') }}
            </span>
            <a class="ml-auto text-sm text-blue-500/80 hover:text-blue-500 cursor-pointer" @click="addDefaultKeywords">
              {{ $t('paneFilter.keywordDefaultBtn') }}
            </a>
          </div>
        </template>

        <template v-else-if="topic.field === 'sort'">
          <button
            v-for="(sort, sortid) in sorts"
            :key="`sort-${sortid}`"
            class="flex items-center gap-x-1 py-1 px-2 rounded-xl default-transition"
            :class="{
              'hover:bg-sky-300 bg-sky-200 text-sky-900': cardFilters.sort?.some(e => e.field === sort.field),
              'hover:bg-zinc-700 bg-zinc-800 text-white': !cardFilters.sort?.some(e => e.field === sort.field)
            }"
            @click="doSort(sort)"
          >
            <div
              class="btn btn-sm"
              :class="{
                'bg-sky-300 text-sky-900': cardFilters.sort?.some(e => e.field === sort.field),
                'bg-zinc-700 text-white': !cardFilters.sort?.some(e => e.field === sort.field)
              }"
            >
              <span class="size-4">
                {{ cardFilters.sort?.findIndex(e => e.field === sort.field) + 1 || '' }}
              </span>
            </div>

            {{ $t(sort.name) }}

            <ArrowUpIcon
              class="size-4 default-transition"
              :class="{
                'rotate-180': cardFilters.sort?.find(e => e.field === sort.field)?.order === 'asc'
              }"
            />
          </button>
        </template>

        <template v-else-if="topic.field === 'productName'">
          <button
            class="w-full rounded-xl py-1 px-2 flex items-center gap-2 default-transition"
            v-for="(filter, filterid) in filters[topic.field]"
            :key="`filter-${topicid}-${filterid}`"
            @click="doSelect(topic.field, filter)"
            :class="{
              'hover:bg-sky-300 bg-sky-200 text-sky-900': cardFilters[topic.field]?.some(e => e.field === filter.field),
              'hover:bg-zinc-700 bg-zinc-800 text-white': !cardFilters[topic.field]?.some(e => e.field === filter.field)
            }"
          >
            <FolderIcon class="size-5 flex-none" />
            <span class="text-left whitespace-pre-line">{{ filter.field?.trim() }}</span>
          </button>
        </template>

        <template v-else>
          <button
            class="rounded-xl py-1 px-2 min-w-[calc((100%-2rem)/5)] sm:min-w-[5rem] md:min-w-[calc((100%-2rem)/5)] default-transition"
            v-for="(filter, filterid) in filters[topic.field]"
            :key="`filter-${topicid}-${filterid}`"
            @click="doSelect(topic.field, filter)"
            :class="{
              'hover:bg-sky-300 bg-sky-200 text-sky-900': cardFilters[topic.field]?.some(e => e.field === filter.field),
              'hover:bg-zinc-700 bg-zinc-800 text-white': !cardFilters[topic.field]?.some(e => e.field === filter.field)
            }"
          >
            <span class="whitespace-pre-line">{{ $t(filter.field) }}</span>
          </button>
        </template>
      </div>
    </section>
  </section>
</template>

<script setup>
import {
  MagnifyingGlassIcon,
  FunnelIcon,
  ChevronDownIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  ArrowUpIcon,
  ArrowRightIcon,
  TrashIcon,
  CheckCircleIcon,
  PlusIcon,
  PaperAirplaneIcon,
  FolderIcon
} from '@heroicons/vue/24/outline'
import {
  MagnifyingGlassIcon as SolidMagnifyingGlassIcon,
  FunnelIcon as SolidFunnelIcon,
  AdjustmentsVerticalIcon as SolidAdjustmentsVerticalIcon,
  StarIcon as SolidStarIcon
} from '@heroicons/vue/24/solid'
import { OnLongPress } from '@vueuse/components'
import { defaultFilter } from '~~/constants/series'

const emits = defineEmits(['change'])
const props = defineProps({
  nowFilters: {
    type: Object,
    default: JSON.parse(JSON.stringify(defaultFilter))
  }
})

const { filters, filterTopics } = storeToRefs(useCardStore())
const { setting } = storeToRefs(usePreferenceStore())
const { messageInput, messageConfirm } = useSwal()
const { t } = useNuxtApp().$i18n
const { doVibrate } = useReactiveVibrate()

const iconMap = ref({
  MagnifyingGlassIcon,
  FunnelIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  SolidMagnifyingGlassIcon,
  SolidFunnelIcon,
  SolidAdjustmentsVerticalIcon,
  SolidStarIcon
})

const cardFilters = ref(JSON.parse(JSON.stringify(defaultFilter)))

const sorts = ref([
  { name: '等級', field: 'level' },
  { name: '顏色', field: 'color' },
  { name: '價格', field: 'price.number' }
])

watch(
  () => props.nowFilters,
  val => {
    cardFilters.value = JSON.parse(JSON.stringify(val))
  },
  { deep: true }
)

const isNoChange = computed(() => {
  return JSON.stringify(cardFilters.value) === JSON.stringify(props.nowFilters)
})

const doDelete = async (f, type = '') => {
  if (!type) return

  const { value } = await messageConfirm(t('paneFilter.delete'), `${t('paneFilter.deleteInfo')} "${type === 'favorite' ? f.name : f}" ?`)
  if (value) {
    // remove f(one of fileterFavorite) from setting.filterFavorite
    if (type === 'favorite') {
      const idx = setting.value.filterFavorite?.findIndex(e => e.name === f.name)
      setting.value.filterFavorite.splice(idx, 1)
    } else if (type === 'keyword') {
      const idx = setting.value.filterKeyword?.findIndex(e => e === f)
      setting.value.filterKeyword.splice(idx, 1)
    }
  }
}

const doSort = sort => {
  const idx = cardFilters.value.sort?.findIndex(e => e.field === sort.field)
  if (idx === -1) {
    cardFilters.value.sort.push({
      field: sort.field,
      order: 'asc'
    })
  } else {
    cardFilters.value.sort[idx].order = cardFilters.value.sort[idx].order === 'asc' ? 'desc' : 'asc'
  }
}

const doSelect = (topic, filter) => {
  const idx = cardFilters.value[topic]?.findIndex(e => e.field === filter.field)
  if (idx === -1) {
    cardFilters.value[topic].push({
      field: filter.field,
      data: filter.data
    })
  } else {
    cardFilters.value[topic].splice(idx, 1)
  }
}

const doTrash = topic => {
  if (topic === 'keyword') {
    cardFilters.value[topic] = ''
  } else {
    cardFilters.value[topic] = []
  }
}

const addFavorite = async () => {
  let filterKeys = Object.keys(cardFilters.value).filter(e => !['keyword', 'sort'].includes(e))

  const { value } = await messageInput(
    t('paneFilter.favorite'),
    `${t('paneFilter.favoriteInfo1')} ${filterKeys.reduce((a, c) => a + cardFilters.value[c]?.length, 0)} ${t('paneFilter.favoriteInfo2')}
    ${cardFilters.value.sort?.length} ${t('paneFilter.favoriteInfo3')}: ${cardFilters.value.keyword}`
  )

  if (value) {
    setting.value.filterFavorite.push({
      name: value,
      value: JSON.parse(JSON.stringify(cardFilters.value))
    })
  }
}

const addDefaultKeywords = () => {
  const defaultKs = ['応援', '集中', '絆', '共鳴', '加速', '経験', 'CXコンボ', '助太刀', 'アンコール']
  setting.value.filterKeyword = [...setting.value.filterKeyword, ...defaultKs].filter((e, i, a) => a.indexOf(e) === i)
}

onMounted(() => {
  cardFilters.value = JSON.parse(JSON.stringify(props.nowFilters))
})

defineExpose({ cardFilters, isNoChange })
</script>
